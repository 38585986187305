/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 14px;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  z-index: -1;
}

.main-card {
  padding: 10px;
  height: 100%;
  overflow: auto; /* Ensure main content can scroll if necessary */
  
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 0;
  height: 100%;
  flex-grow: 1;
}

.navbar a {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none !important;
  z-index: 10;
}

.navbar a.active {
  color: #fff;
  text-decoration: none !important;
  z-index: 10;
}
